<template>
  <offer-modal
    v-if="showOfferDetailsModal"
    :offer-template-ids="templateIds"
    is-self-funded
    :requested-offer-template-id="requestedOfferTemplateId"
    @close="showOfferDetailsModal = false"
  />
  <primary-button
    v-bind="$attrs"
    role="createSelfFundedOfferButton"
    :disabled="disabled"
    :class="{ 'border border-gray-300': disabled }"
    @click="showOfferDetailsModal = true"
  >
    {{ $t("DEALS.DEAL_PROGRESS.CREATE_OFFER") }}
  </primary-button>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useActiveWorkflowTemplate } from "@/hooks/workflow";
import type { WfbFunderRecord } from "@/models/workflows";
import OfferModal from "@/views/deals/components/OfferModal.vue";
import { useStore } from "vuex";
defineProps({
  disabled: {
    type: Boolean,
    default: false
  }
});

const { getters } = useStore();

const showOfferDetailsModal = ref(false);

const { allSelectedOfferFunders } = useActiveWorkflowTemplate();
const templateIds = computed(() => {
  return allSelectedOfferFunders.value.reduce(
    (acc: string[], funderRecord: WfbFunderRecord) => {
      funderRecord.offer_templates.forEach((offerTemplate) => {
        if (offerTemplate.enabled) {
          acc.push(offerTemplate.id);
        }
      });
      return acc;
    },
    []
  );
});

const requestedOfferTemplateId = computed<string>(() => {
  const placements = getters["applications/placements"];
  if (!placements.length) {
    return null;
  }
  return placements[0].destinations[0].offer_template_id;
});
</script>
